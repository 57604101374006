import React, { useState, useEffect } from 'react';
import { ref, get, onValue } from 'firebase/database';
import { GoogleLogin } from '@react-oauth/google'
import jwt_decode from 'jwt-decode'
import { db } from './settings/firebase'
import './App.css';

const App = () => {
  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  });
  const [loginUser, setLoginUser] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('all');
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    populateUsersDropdown()
  }, []);

  const handleLogin = response => {
    const decodedToken = jwt_decode(response.credential)
    setLoginUser(decodedToken.email)
    console.log("Google Account:", decodedToken.name, decodedToken.email)
  }

  const handleLogout = () => {
    console.log('Logged out successfully!')
    setLoginUser('')
  }

  const populateUsersDropdown = async () => {
    const usersRef = ref(db, 'users');
    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        let fetchedUsers = snapshot.val();
        setUserList(fetchedUsers);  // Update the users state here
      } else {
        console.log("No data found.");
      }
    } catch (error) {
      console.error("Error reading data: ", error);
    }
  };
  
  const filterByDateAndUser = event => {
    event.preventDefault();

    if (!date) {
        alert("Please select a date to continue.");
        return;
    }

    // Convert the standard date format 'YYYY-MM-DD' to 'YYMMDD'
    const year = date.slice(2,4);
    const month = date.slice(5,7);
    const day = date.slice(8,10);
    const formattedDate = `20${year}${month}${day}`;

    // Reference the specific date folder inside "thermometer"
    const thermometerRef = ref(db, `thermometer/${formattedDate}`);
    
    onValue(thermometerRef, (snapshot) => {
      if(snapshot.exists()) {
        let thermometers = snapshot.val();
        const filteredData = Object.values(thermometers).filter(entry => selectedUser === 'all' || entry.sender === selectedUser).reverse();
        setTableData(filteredData);
      } else {
        console.log("No data found for the specified date and user.");
      }
    }, (error) => {
      console.error("Error reading data: ", error);
    });
  };

  return (
    <div className="App">
      <div className="content-wrapper">
        <img id="logo" src="images/mediq.png" alt="MEDIQ Financial Services" />
        {loginUser ? 
          (<button className="logoutButton" onClick={handleLogout}>Logout</button>)
          :
          <GoogleLogin 
            onSuccess={handleLogin}
            onError = {() => {
              console.log('Login Failed!')
            }}
          />
        }
      </div>

      <main style={{ display: loginUser ? 'block' : 'none' }}>
        <form id="userFilter" onSubmit={filterByDateAndUser}>
          <label htmlFor="filterDate">Date:</label>
          <input type="date" id="filterDate" value={date} onChange={e => setDate(e.target.value)} />
          
          <label htmlFor="usersDropdown">User:</label>
          <select id="usersDropdown" value={selectedUser} onChange={e => setSelectedUser(e.target.value)}>
            <option value="all">All</option>
            {Object.entries(userList).map(([key, value]) => (
              <option key={key} value={value}>{key}</option>
            ))}
          </select>

          <button type="submit">Fetch</button>
        </form>

        <table id="data-table">
          <thead>
            <tr>
              <th>Email ID</th>
              <th>Sender</th>
              <th>Subject</th>
              <th>Recipients</th>
              <th>Send Time</th>
              <th>Medal</th>
              <th>Comment</th>
              <th>Submit Time</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map(entry => (
              <tr key={entry.emailID}>
                <td>{entry.emailID || '-'}</td>
                <td>{entry.sender || '-'}</td>
                <td>{entry.subject || '-'}</td>
                <td>{entry.recipients || '-'}</td>
                <td>{entry.sendTime || '-'}</td>
                <td>{entry.medal || '-'}</td>
                <td>{entry.comment || '-'}</td>
                <td>{entry.submitTime || '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </div>
  );
}

export default App;
