import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

var firebaseConfig = {
  apiKey: "AIzaSyBF3fisLZ1QaJL2sNev8JMBfQfBZVm67OY",
  authDomain: "signature-377500.firebaseapp.com",
  databaseURL: "https://signature-377500-default-rtdb.asia-southeast1.firebasedatabase.app/",
  // databaseURL: "http://127.0.0.1:9000/?ns=signature-377500-default-rtdb",
  projectId: "signature-377500",
  storageBucket: "signature-377500.appspot.com",
  messagingSenderId: "165962495787",
  appId: "1:165962495787:web:d3ea81a317fd1e843db76e"
}

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
